//Category icons
import singer from './Assets/Icons/singer.png'
import band from './Assets/Icons/band.png'
import harp from './Assets/Icons/harp.png'
import dj from './Assets/Icons/dj.png'
import sax from './Assets/Icons/sax.png'
import piano from './Assets/Icons/piano.png'
//Schedule icons
import drinks from './Assets/Icons/champagne.png'
import main from './Assets/Icons/disco-ball.png'
import makeup from './Assets/Icons/cosmetics.png'
import bouquet from './Assets/Icons/bouquet.png'
import dress from './Assets/Icons/dress.png'
import guests from './Assets/Icons/hall.png'
import guestsSeated from './Assets/Icons/pew.png'
import ceremony from './Assets/Icons/rings.png'
import photographs from './Assets/Icons/camera.png'
import dinner from './Assets/Icons/meal.png'
import speech from './Assets/Icons/speech.png'
import cake from './Assets/Icons/wedding-cake.png'
import changed from './Assets/Icons/pause.png'
import eveningGuests from './Assets/Icons/guests.png'
import firstDance from './Assets/Icons/dance.png'
import hometime from './Assets/Icons/wedding-car.png'
import bride from './Assets/Icons/bride.png'
import groom from './Assets/Icons/groom.png'
import aisle from './Assets/Icons/couple.png'

export const UnspecifiedScheduleId = 0 // position of WeddingSchedule item Other

export const ActTypes = [
    { name: 'Singer', icon: singer },
    { name: 'Band', icon: band },
    { name: 'Sax', icon: sax },
    { name: 'DJ', icon: dj },
    { name: 'Piano', icon: piano },
    { name: 'Harp', icon: harp }
];

export const Timings = [
    {name: 'Drinks Reception', icon: drinks },
    {name: 'Main Entertainment', icon: main },
];

export const Locations = [
    'North West',
    'North East',
    'Yorkshire',
    'West Midlands',
    'East Midlands',
    'East of England',
    'South West',
    'South East',
    'London',
    'North Wales',
    'South Wales',
    'Scotland',
    'Abroad'
];

export const WeddingSchedule = [
    {id: 0, name: 'Other/Unspecified', icon: main }, // TBD Needs own icon
    {id: 1, name: 'Hair & make-up', icon: makeup },
    {id: 2, name: 'Bouquets arrive', icon: bouquet },
    {id: 3, name: 'Get dressed', icon: dress },
    {id: 4, name: 'Groom arrives', icon: groom },
    {id: 5, name: 'Guests arrive', icon: guests },
    {id: 6, name: 'Guests seated', icon: guestsSeated },
    {id: 7, name: 'Bride arrives', icon: bride },
    {id: 8, name: 'Aisle walk', icon: aisle },
    {id: 9, name: 'Ceremony', icon: ceremony },
    // Drinks Reception
    ...Timings.slice(0, 1).map((item, index) => ({
        id: 10 + index, name: item.name, icon: item.icon
    })),
    {id: 11, name: 'Photographs', icon: photographs },
    {id: 12, name: 'Dinner', icon: dinner },
    {id: 13, name: 'Speeches', icon: speech },
    {id: 14, name: 'Cutting of the cake', icon: cake },
    {id: 15, name: 'Get Changed', icon: changed },
    {id: 16, name: 'Evening guests arrive', icon: eveningGuests },
    {id: 17, name: 'First Dance', icon: firstDance },
    // Main Entertainment
    ...Timings.slice(1, 2).map((item, index) => ({
        id: 18 + index, name: item.name, icon: item.icon
    })),    {id: 19, name: 'DJ', icon: dj },
    {id: 20, name: 'Party Finishes', icon: hometime },
]

//For any routes with a white navbar
export const invertedNavbarRoutes = [
    '/',
    '/competition',
    '/blogs/how-it-works',
    '/app'
]
