import React, { useEffect, useState } from 'react'
import close from '../../../Assets/Icons/close.png'
import './Modal.css'
import { toast } from 'react-toastify'
import ToggleSwitch from '../ToggleSwitch';
import { LogToServer } from '../../../Utilities'

export default function EditQuote({setOpen, acts, setQuoteOptions, quoteOptions, quoteIndex, artistEmail}) {

    const handleContainerClick = (e) => {
        e.stopPropagation(); // Prevents click event from bubbling up to the modal div
      };

    const vatRateLow = 1.0
    const vatRateHigh = 1.20

    const [quoteId, setQuoteId ] = useState("")
    const [vat, setVat ] = useState(vatRateLow)
    const [name, setName ] = useState("")
    const [amount, setAmount ] = useState("")
    const [gross, setGross ] = useState("")
    const [actId, setActId ] = useState("")
    const [actName, setActName ] = useState("")
    const [description, setDescription ] = useState("")
    const [isOn, setIsOn ] = useState(false)

    useEffect(() => {
        if (isOn) {
            setVat(vatRateHigh)
        } else {
            setVat(vatRateLow)
        }
    }, [isOn])

    useEffect(() => {
        if (quoteIndex >= 0) {
            let quote = quoteOptions.find((_, index) => quoteIndex == index)
            console.log(quote)
            let {quoteName, selectedActId, artistFee, vatRate, description, _id} = quote;
            setName(quoteName)
            setActId(selectedActId)
            setAmount(artistFee)
            setVat(vatRate)
            setDescription(description)
            setQuoteId(_id)

            if (vatRate == 1.2) {
                setIsOn(true)
            } else {
                setIsOn(false)
            }
        }
    }, [quoteIndex])

    useEffect(() => {
        function updateGross() {
            let gross = (amount === 0.3) ? 0.3 : Math.round(amount * vat * 100) / 100
            setGross(gross)
        }

        if (amount) {
            updateGross()
        } else {
            setGross("")
        }

    }, [amount, vat])

    useEffect(() => {
        if (actId) {
            let actInfo = acts.find((act) => act._id === actId)
            setActName(actInfo.actName)
        } else {
            setActName("")
        }
    }, [actId])

    const handleUpdate = () => {
        // Guard against message text that includes pricing or emails
        // i.e. anything hinting at showing confusing pricing or backdooring the platform
        // TBD consider generic handler - duplicated in new quote modal and request module itself
        let errorString
        if (description.includes(gross.toString())) {
            errorString = "Please do not duplicate quote prices in your message text."
        }
        else if (description.includes(artistEmail)) {
            errorString = "Use in-app messaging for contact at this time in preference to direct email in your message text."
        }

        if (errorString) {
            toast.error(errorString)

            LogToServer(`Attempt to include illegal items in quote message by artist ${artistEmail}`, errorString)
            return
        }

        // check for warnable content in the message
        if (
            description.match(/\b\d{6,}\b/) ||
            description.match(/[a-zA-Z]+@[a-zA-Z]+/)
        ) {
            errorString = "Use in-app messaging for contact at this time."
            const userConfirmed = window.confirm(
                "Message text advisory:\n\n" +
                "Communication is securely handled by your app's inbox and is best managed there.\n\n" +
                "If you feel your message is good to go then select OK to continue, otherwise cancel and amend as needed."
            )

            LogToServer(`Possible backdoor content in quote message by artist ${artistEmail}`, "Suspect content in quote message")

            if (!userConfirmed) {
                return
            }
        }

        if (!gross) {
            const userConfirmed = window.confirm(
                "You have created a £0 (free) quote.\n\n" +
                "If you are sure then select OK to continue, otherwise cancel and amend as needed."
            )

            if (!userConfirmed) {
                return
            }
        }

        if (!name || !amount || !actId) {
            toast.error("Please add all fields");
        } else {
            let payload = {
                _id: quoteId,
                quoteName: name,
                artistFee: amount,
                vatRate: vat,
                selectedActId: actId,
                selectedActName : actName,
                description,
                expiration: {
                    refreshedAt: new Date(),
                    expired: false
                }
            };

            setQuoteOptions((prev) => {
                if (quoteIndex >= 0) {
                    // Update the existing quote at the specified index
                    return prev.map((quote, index) =>
                        index === quoteIndex ? payload : quote
                    );
                } else {
                    // Add a new quote
                    return [...prev, payload];
                }
            });

            setOpen(false);
        }
    };


  return (
    <div className='modal' onClick={(e) =>  setOpen(false)}>

        <div className='modal-container' onClick={handleContainerClick}>
          <img
            className='close-modal-btn hover'
            src={close}
            alt='Close First Dance Music modal'
            onClick={() => setOpen(false)}
          />
          <h3 className='modal-header' style={{color: '#313131'}}>Edit quote option</h3>

          <p>Create multiple quote options to provide clients with the best choice to suit. From multiple set times to different size band options.</p>

            <label className='artist-modal-label'>Quote name</label>
            <input
                className='single-input'
                placeholder='eg. DJ + Sax | 2 x 45min | 4-piece band'
                value={name}
                onChange={(e) => setName(e.target.value)}
            />

            <label className='artist-modal-label'>Select Act</label>
            <select
                className='single-input'
                onChange={(e) => setActId(e.target.value)}
                value={actId}
            >
                <option value="">Please select</option>
                {acts.map((act) => {
                    return (
                        <option key={act._id} value={act._id}>
                            {act.actName}
                        </option>
                    )
                })}
            </select>

            <div style={{display:'flex', alignItems:'center'}}>
                <div>
                    <label style={{display:'block'}} className='artist-modal-label'>Amount</label>
                    <div className="currency-input-container">
                        <span className="currency-symbol">£</span>
                        <input
                            className="withdraw-input"
                            style={{width: 120}}
                            placeholder="0.00"
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                        />
                    </div>
                </div>


                <div style={{marginLeft: 30, marginTop: 10}}>
                    <label className='artist-modal-label'>
                        VAT ({vat == vatRateLow ? 0 : 20}%)
                    </label>
                    <ToggleSwitch isOn={isOn} setIsOn={setIsOn} />

                </div>
            </div>

            <label className='artist-modal-label'>Total</label>
            <div className="currency-input-container">
                <span className="currency-symbol">£</span>
                <input
                    className="withdraw-input"
                    style={{width: 120}}
                    placeholder="0.00"
                    type="number"
                    value={gross}
                    disabled
                />
            </div>

            <label className='artist-modal-label'>Description</label>
            <textarea
                style={{height: 100, padding: 10, resize: 'none'}}
                className='single-input'
                placeholder='Please provide as much detail as possible for this quote such as set times and what is included.'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
            />

            <button style={{marginTop: 20}} className='primary-btn' onClick={handleUpdate}>Update Quote</button>

            <p style={{fontSize: 14, color: 'grey'}}>
                This won't be sent to the client until you submit your quotes.
            </p>
        </div>

    </div>
  )
}
