import React, { useEffect, useState} from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { LogToServer } from '../../Utilities'
import './Quote.css'
import ClientFAQ from './ClientFAQ.js';
import hearts from '../../Assets/hearts.png'
import QuoteClientContainer from './QuoteClientContainer.js';
import PaymentModal from './Modals/PaymentModal.js';
import { toast } from 'react-toastify'
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Display summarised quote information, along with original request, to a client
// Payment button is context sensitive. If payment has not yet been made then the
// button is displayed which will open a modal detailing one-off or the payment
// plan along with credit card entry. Completion of payment navigagtes to a
// congratulations page, updating db and sending confirmation emails. Page
// now shows as paid whenever it is rendered.
export default function Quote() {

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

    let { id } = useParams()
    let navigate = useNavigate()
    const [artist, setArtist] = useState("")
    const [request, setRequest] = useState("")
    const [quote, setQuote] = useState("")
    const [quoteOptions, setQuoteOptions] = useState([])
    const [customerGross, setCustomerGross] = useState(0)
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [selectedQuote, setSelectedQuote] = useState("")
    const [quotePaid, setQuotePaid] = useState(false)
    const [atLeastOneQuoteNotExpired, setAtLeastOneQuoteNotExpired] = useState(false)

    useEffect(() => {
        getQuotes()
    }, [])

    useEffect(() => {
        // Set Customer gross when a quote is selected
        if (quote) {
            if (quote.customerGross === "0.3") {
                setCustomerGross(parseFloat(quote.customerGross))
            } else {
                setCustomerGross(Math.round(quote.customerGross * 100) / 100)
            }
        }
    }, [quote])

    const handlePaymentSuccess = (paymentData) => {
        // payment has just been made so create the booking from the request
        console.log("Handle Payment Success", paymentData.message)

        // remove the payment form
        setShowPaymentForm(false)

        updateQuoteInfo()
        createBooking(paymentData.stripeCustomerId)
        updateAndNotifyBookedRequest(paymentData.paymentSummary)
        notifyQuotingArtists()

        LogToServer('Booking and purchase complete, navigating to success page.')

        // Navigate to success page for analytics
        navigate('/payment-success')
    };

    const updateQuoteInfo = async () => {
        try {
            const updatedQuote = { ...quote, hasPaid: true }
            const updatedData = await axios.post(`${process.env.REACT_APP_API}/quotes/update-quote`, { updatedQuote })
            // TBD TODO: Add notification, we want to log and alert admin if has paid does not get set. Can be serverside
            // Don't hang around for the endpoint, just update the local quote state directly to ensure re-render
            setQuote((prevQuote) => ({ ...prevQuote, hasPaid: true }))
        } catch (error) {
            console.log("Error updating quote", error)
        }
    };

    const createBooking = (stripeCustomerId) => {
        let payload = {
            title: `${request.name} - ${request.info.actType}`,
            artistId: artist._id,
            requestId: request._id,
            stripeCustomerId,
            clientId: request.clientId,
            date: moment(request.date).format("YYYY-MM-DD"),
            venueAddress: request.info?.venue || "",
            clientName: request.name || "",
            notes: request.notes || "",
        }
        axios.post(`${process.env.REACT_APP_API}/bookings/create-new-booking`, payload)
        .then(() => {
            console.log("Booking created for artist")
        })
        .catch(error => {
            console.log("Error creating booking", error)
        })
    }

    const updateAndNotifyBookedRequest = async (paymentSummary) => {
        // Update the request with the quote Id and artist Id to bind them
        // If this was from a direct artist request the artist Id may well
        // already have been present
        const updatedRequest = { ...request, quoteId: quote._id, artistId: artist._id, fulfilled: true }

        try {
            const res = await axios.put(`${process.env.REACT_APP_API}/requests/update-request`, { updatedRequest })
            console.log("Request updated with Ids for artist and quote");
            setRequest(res.data);

            // send notifications
            // ideally this would be done even if the update above failed but safer to keep quiet until
            // whatever failed there is fixed. Any failure there will be present in system info of admin panel
            axios.post(`${process.env.REACT_APP_API}/bookings/send-booking-confirmations`, { request: res.data, artist, paymentSummary } )
            .then(() => {
                console.log("Booking confirmation emails sent to client and artist")
            })
            .catch(error => {
                console.log("Error during send booking confirmations", error)
            });
        } catch (error) {
            console.log("Error during update and notify of booked request", error)
        }
    }

    // Any artist who has already engaged this client by providing a quote
    // should be notified that the request is no longer available. Since a
    // quote is initiated along with an inbox, this can be done by auto
    // appending a message to the chat
    const notifyQuotingArtists = async () => {
        axios.post(`${process.env.REACT_APP_API}/chats/notify-quoting-artists`, { requestId: request._id, artistId: artist._id } )
        .then(() => {
            console.log("Artists who have provided quotes have now been notified")
        })
        .catch(error => {
            console.log("Error notifying quoting artists", error)
        })
    }

    const getRequest = (id, quoteOptions) => {
        axios.post(`${process.env.REACT_APP_API}/requests/get-request-by-id`, {id})
        .then((res)=>{
            setRequest(res.data)

            const actPriceList = quoteOptions.map(quote =>
                `For act ${quote.selectedActName}, amount is £${quote.customerGross}.`
            ).join('. ')

            LogToServer(`Client ${res.data.name} is viewing quotes. ${actPriceList}`)
        })
        .catch((error) => console.log("Error getting request", error))
    }

    const getArtistInfo = (id) => {
        axios.post(`${process.env.REACT_APP_API}/users/get-artist-by-id`, {id})
        .then((res) => {
            setArtist(res.data);
        })
        .catch((error) => console.log("Error getting artist info", error))
    }

    const getQuotes = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API}/quotes/get-quotes-by-id`, {id})
            setQuoteOptions(res.data.quotes)
            getRequest(res.data.requestId, res.data.quotes)
            getArtistInfo(res.data.artistId)

            const hasPaidInQuotes = res.data.quotes.map(quote => quote.hasPaid).includes(true)
            setQuotePaid(hasPaidInQuotes)


            const atLeastOneQuoteNotExpired = res.data.quotes.map(quote => quote?.expiration?.expired).includes(false)
            setAtLeastOneQuoteNotExpired(atLeastOneQuoteNotExpired)
        } catch (error) {
            console.log("Error getting quotes", error)
        }
    }

    const freeBooking = () => {
        // this is a free booking
        LogToServer(`Zero cost booking being made for client ${request.clientId}`)

        const stripeCustomerId = "dummy" + request.clientId
        const paymentSummary = `Zero cost booking being made for ${request.clientId}`

        const paymentData = {
            message: 'Free Booking Successfully Made',
            stripeCustomerId,
            paymentSummary
        }

        handlePaymentSuccess(paymentData)
    }

    const continueToPayment = () => {
        if(!quote){
            toast.error("Please select a quote")
        } else {
            setShowPaymentForm(true)
        }
    }

    return (
        <div className='client-outlet' >
            <div style={{display:'flex', flexDirection: 'row', flexWrap:'wrap'}}>

            <div id='wedding-request-info'>
                <h2 style={{fontWeight: 900, marginBottom: 30}}>Wedding Details</h2>
                <p className='request-header'>Client name - <span className='request-line-info'>{request && request.name}</span></p>
                <p className='request-header'>Date of wedding - <span className='request-line-info'>{moment(request.date).format('ddd, DD MMM YYYY')}</span></p>
                <p className='request-header'>Act requested - <span className='request-line-info'>{request && request.info.actType}</span></p>
                <p className='request-header'>Start time - <span className='request-line-info'>{request && request.info.startTime}</span></p>
                <p className='request-header'>End time - <span className='request-line-info'>{request && request.info.endTime}</span></p>
                <p className='request-header'>Venue - <span className='request-line-info'>{request && request.info.venue}</span></p>
                <p className='request-header'>Location - <span className='request-line-info'>{request && request.info.location}</span></p>
                <p className='request-header'>Notes -</p>
                <p className='request-line-info'>{request.notes}</p>

                <img id='artist-request-hearts' src={hearts} alt='First Dance Music Wedding hearts'/>
            </div>

            <div id='create-quotes-container'>
                {quotePaid ? (
                    <h2 style={{color: '#edbcba', fontWeight: 900}}>Congratulations! Your wedding music is booked</h2>
                ) : (request.fulfilled ?
                        <>
                            <h2 style={{color: '#edbcba', marginBottom: '0px', fontWeight: 900}}>You accepted an alternative act</h2>
                            <p style={{ fontSize: '12px', marginTop: '0px', marginBottom: '20px' }}>
                                Please check your My Wedding page
                            </p>
                        </>
                    :
                        <h2 style={{ fontWeight: 900}}>Your Quotes</h2>
                )}

                {quoteOptions.map((quoteData, i) => {
                    return (
                        <QuoteClientContainer
                            key={i}
                            quote={quoteData}
                            setQuote={setQuote}
                            i={i}
                            selectedQuote={selectedQuote}
                            setSelectedQuote={setSelectedQuote}
                        />
                    )
                })}

                {/* expired must be present and false to allow booking. Older quotes will not have this field */}
                {(!quotePaid && !request.fulfilled && atLeastOneQuoteNotExpired) && (
                    (customerGross === 0 && quote) ? (
                        <button id="quote-btn"
                            style={{ fontSize: '16px', padding: '10px 20px' }}
                            onClick={freeBooking}
                        >
                            Free Booking
                        </button>
                    ) : (
                        <button className="transparent-btn"
                            onClick={continueToPayment}
                        >
                            Continue to Payment
                        </button>
                    ))
                }
            </div>

            </div>

            {showPaymentForm &&
                <Elements stripe = {stripePromise}>
                    <PaymentModal
                        handlePaymentSuccess={handlePaymentSuccess}
                        setOpen={setShowPaymentForm}
                        request={request}
                        customerGross={customerGross}
                        quote={quote}
                    />
                </Elements>
            }
            <ClientFAQ />
        </div>
    )
}
