import React, { useEffect, useState, useRef } from 'react'
import { Link, useOutletContext, useParams } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { useAuth } from '../../Context/AuthContext'
import { UseInbox } from '../../Context/InboxContext'
import './ClientInbox.css'
import EmojiPicker from 'emoji-picker-react';
import QuoteClientChatContainer from './QuoteClientChatContainer'
// Icons
import send from '../../Assets/Icons/send.png'
import hearts from '../../Assets/dash-hearts.png'
import emoji from '../../Assets/Icons/emoji.png'
import up from '../../Assets/Icons/up.png'

// client side chat for creating messages from client to artist or admin
export default function ClientChat() {

    let { role } = useAuth()
    let { getUnreadChats } = UseInbox()

    let { id } = useParams()
    const [messages, setMessages] = useState([])
    const [message, setMessage] = useState("")
    const [chat, setChat] = useState("")
    const [emojisOpen, setEmojisOpen] = useState(false)
    const [quoteUrl, setQuoteUrl] = useState("")
    const [quoteOptions, setQuoteOptions] = useState([])

    const messagesEndRef = useRef(null)
    const QuotesRef = useRef(null)

    const { getChats } = useOutletContext()

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const scrollToTop= () => {
        QuotesRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [messages]);

    useEffect(() => {
        getChat()
        getMessages()
    }, [id])

    const getChat = () => {
        axios.post(`${process.env.REACT_APP_API}/chats/get-client-chat-by-id`, {chatId: id})
        .then((res) => {
            setChat(res.data)
            getChats() // This will render as read in the inbox side bar
            getQuotes(res.data)
        })
        .catch((error) => console.log(error))
    }

    const getQuotes = (data) => {
        // get all the quotes for the artist for that request
        axios.post(`${process.env.REACT_APP_API}/quotes/get-artist-quotes-for-request`, {
            artistId: data.artistId,
            requestId: data.requestId
        })
        .then((res) => {
            setQuoteOptions(res.data)
             // The first of the list does the job since all are displayed on the same page
            const quoteURL = `/clients/quote/${res.data[0]._id}`
            setQuoteUrl(quoteURL)

        })
        .catch((error) => {
            console.log("Error getting artist quote for request", error)
        })
    }

    const getMessages = () => {
        axios.post(`${process.env.REACT_APP_API}/chats/get-messages-by-chat-id`, {chatId: id})
        .then((res) => {
            setMessages(res.data)
            getUnreadChats()
        })
        .catch((error) => console.log(error))
    }

    const sendMessageToArtist = () => {
        if (!message || !role) {
            return
        } else {
            axios.post(`${process.env.REACT_APP_API}/chats/send-client-message`, {
                chatId: id,
                artistEmail: chat.artistEmail,
                artistId: chat.artistId,
                message,
                sentBy: role.charAt(0).toUpperCase() + role.slice(1)
            })
            .then((res) => {
                setMessage("")
                getMessages()
                getChats()
            })
            .catch((error) => console.log("Error sending message to artist", error))
        }
    }

    const sendMessageToSupport = () => {
        if (!message) {
            return
        } else {
            axios.post(`${process.env.REACT_APP_API}/chats/send-message-to-adminsupport`, {
                chatId: id,
                message,
                senderName: chat.clientName,
                senderEmail: chat.clientEmail,
                sentBy: "Client"
            })
            .then((res) => {
                setMessage("")
                getMessages()
                getChats()
            })
            .catch((error) => console.log(error))
        }
    }

    const renderMessage = (msg, i) => {
        const lines = String(msg.message).split("\n")

        return (
            <div key={`msg-${i}`}>
                {lines.map((line, index) => {
                    //console.log("- ", line)
                    // NOTE quoteRegex checks are purley to accommodate the original
                    // automated message sent when an artist quotes. Could be removed
                    // in the future once all such quotes become obsolete
                    // i.e. all associated request wedding data are in the past
                    const quoteRegex = /Quote ([a-fA-F0-9]{24})/
                    const actRegex = /For proposed act (.*?): (.*)/
                    const genReqText = /Please try a general request/
                    const quoteMatch = line.match(quoteRegex)
                    const actMatch = line.match(actRegex)
                    const genReqMatch = line.match(genReqText)

                    if (quoteMatch) {
                        const quoteId = quoteMatch[1]
                        const quoteURL = `/clients/quote/${quoteId}`

                        return (
                            <p key={`quote-${i}-${index}`}>
                                <a
                                    style={{ fontFamily: 'Mont', color: 'grey' }}
                                    href={quoteURL}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    View Quote
                                </a>
                            </p>
                        )
                    } else if (actMatch) {
                        const prefix = line.split(actMatch[1])[0];
                        const actURL = `/find/artists/${actMatch[2]}`;
                        return (
                            <p key={`act-${i}-${index}`}>
                                <span>{prefix}</span>
                                <a
                                    style={{ fontFamily: 'Mont', color: 'grey' }}
                                    href={actURL}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {actMatch[1]}
                                </a>
                            </p>
                        )
                    } else if (genReqMatch) {
                        const prefix = line.split(genReqMatch[1])[0]
                        const findURL = `/find`
                        return (
                            <p key={`genReq-${i}-${index}`}>
                                <span>{prefix}</span>
                                &nbsp;
                                <a href={findURL} target="_blank" rel="noopener noreferrer">
                                    here
                                </a>
                            </p>
                        );
                    } else {
                        // For other lines without matches
                        return (
                            <React.Fragment key={`line-${i}-${index}`}>
                                {line} <br />
                            </React.Fragment>
                        );
                    }
                })}
            </div>
        );
    };

    const handleEmoji = (emoji) => {
        setMessage(`${message}${emoji.emoji}`)
        setEmojisOpen(false)
    }

    return (
        <div className='client-chat'>
             <div ref={QuotesRef}/>

            {!chat.ticketId && <div id='client-chat-fab'>
                <p style={{color: '#a9a9a9', backgroundColor: 'white'}}>Happy with a quote?</p>
                <Link to={quoteUrl}>
                    <button className='primary-btn'>Book Now</button>
                </Link>
            </div>}

            {quoteOptions.map((quote, i) => {
                return <QuoteClientChatContainer quote={quote} i={i} quoteURL={quoteUrl} key={i}/>
            })}

            {messages.map((msg, i) => {
                return <div key={`container-${i}`} className={msg.sentBy === "Client" ? 'client-msg-container' : undefined}>
                    <div key={`msg-${i}`} className={msg.sentBy === "Client" ? 'client-msg-user' : 'client-msg-other'}>

                        {renderMessage(msg, i)}

                        <p className='client-timestamp'>{moment(msg.timeStamp).fromNow()}
                            {chat.ticketId && (
                                <>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <em title={`${chat.ticketId}`}>
                                        Support reference: {chat.ticketId.slice(-10)}
                                    </em>
                                </>
                            )}
                        </p>
                    </div>

                    {(i === messages.length - 2) && (
                        <div ref={messagesEndRef}> </div>
                    )}
                </div>

            })}

            {messages.length > 5 &&
                <p
                    className='hover'
                    style={{textAlign:'center', zIndex: 3, position:'relative', letterSpacing: 2, textTransform: 'uppercase', color: '#EBA7A2'}}
                    onClick={scrollToTop}
                >
                    Scroll to quotes
                    <img style={{height: 15, marginLeft: 10}} src={up}/>
                </p>
            }

            <img id='chat-hearts' src={hearts} alt='First Dance Music Wedding Hearts'/>

            <div id='client-message-container'>
                <div id='client-msg-box'>
                    <img id='client-emoji-selector' className='hover' src={emoji} alt='First Dance Music Wedding Emoji' onClick={() => setEmojisOpen(!emojisOpen)}/>
                    <textarea
                        id='client-chat-textarea'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onFocus={() => setEmojisOpen(false)}
                        placeholder='Your message'
                    />

                    <img id='client-send-icon' className='hover' src={send} onClick={chat.ticketId ? sendMessageToSupport : sendMessageToArtist}/>
                </div>
                <div>
                    <EmojiPicker
                        open={emojisOpen}
                        style={{position: 'fixed', bottom: 150, zIndex: 1000}}
                        onEmojiClick={(emoji) => handleEmoji(emoji)}

                    />
                </div>


            </div>
        </div>
    )
}
