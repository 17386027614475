import React from 'react'
import './ClientHowItWorks.css'
import hero from '../../../Assets/how-it-works-client.webp'
import heroMobile from '../../../Assets/how-it-works-client-mobile.webp'
import laptop from '../../../Assets/laptop.webp'
import postBtn from '../../../Assets/post-btn.webp'
import filters from '../../../Assets/filters.webp'
import filtersMobile from '../../../Assets/filter-mobile.webp'
import bell from '../../../Assets/bell.png'
import hearts from '../../../Assets/heart+star.png'
import dashboard from '../../../Assets/how-it-works-dash.webp'
import finance from '../../../Assets/Icons/0.png'
import credit from '../../../Assets/Icons/credit.png'
import imageAndHearts from '../../../Assets/image+hearts.webp'
import imageAndHeartsMobile from '../../../Assets/imageAndheartsMobile.webp'
import phone from '../../../Assets/clientPhone.webp'
import request from '../../../Assets/requestAct.png'

export default function ClientHowItWorks() {
  return (
    <div >
      <div className='client-how-it-works-hero'>

        <img 
          className='client-how-it-works-img mobile-remove' 
          src={hero} 
          alt='First Dance Music | How to book your wedding music'
        />

        <div className='hero-info'>
          <h1>How It Works</h1>
          <p>With First Dance Music, planning the music for your wedding is simple, stress-free, and enjoyable. Create unforgettable memories for your special day in just a few easy steps!</p>
          <div>
            <button style={{fontSize: 20, marginRight: 10}} className='solid-black-btn'>Get Started</button>
            <button style={{fontSize: 20}} className='black-btn'>Learn More</button>
          </div>
        </div>

        <img 
          className='mobile' 
          src={heroMobile}
          alt='First Dance Music | How to book your wedding music'
          style={{width: '100vw', marginTop: 50}}
        />

      </div>
      
      {/* Step 1 */}

      <div className='client-how-it-works-step'>
        <div className='client-how-it-works-step-container'>
          <div className='client-how-it-works-step-info'>
            <h2 style={{color: '#EABAB7', fontWeight: 900, fontSize: 40, margin: 0}}>01.</h2>
            <h2 style={{fontWeight: 900, fontSize: 26}}>Sign up & view your wedding dashboard</h2>
            <p>Click ‘Post Wedding Request’</p>
            <img src={postBtn} style={{width: '60%'}}/>

            <p>Enter key details like your wedding date and music preferences. By submitting, you'll automatically create a free account.</p>
            <p>Welcome to your personalised dashboard, complete with a countdown to your big day and a handy wedding day planner!</p>
          </div>
          <img 
            src={laptop} 
            className='client-how-it-works-img'
            alt='First Dance Music | How to book your wedding music'
          />
        </div>
      </div>
      
      {/* Step 2 */}

      <div className='client-how-it-works-step-container'>
        <div className='client-how-it-works-step-info' style={{maxWidth: '800px'}}>
            <h2 style={{color: '#EABAB7', fontWeight: 900, fontSize: 40, margin: 0}}>02.</h2>
            <h2 style={{fontWeight: 900, fontSize: 26}}>Choose how you want to find your musician</h2>
            <p>You’ve got two easy options for finding the perfect wedding entertainment</p>

            <div style={{display:'flex', flexWrap:'wrap', justifyContent:'space-between', alignItems:'center', fontWeight: 600, fontSize: 16}}>
              <div style={{width: 320, maxWidth: '100%'}}>
                <p style={{color: '#EABAB7', fontWeight: 900}}>Post a general request</p>
                <p>Fill out the request form with your event details—music start/end times, your vision for the day, and anything else you want to share. Then sit back and let the quotes come to you. Available musicians will reply, and you can pick the one you love!</p>
              </div>

              <p style={{color: '#EABAB7', fontWeight: 900, margin: '0 50px'}}>Or</p>

              <div style={{width: 320, maxWidth: '100%'}}>
                <p style={{color: '#EABAB7', fontWeight: 900}}>Browse & request directly</p>
                <p>Prefer to hand-pick your musician? No problem! Browse our talented lineup you can filter by act type—bands, harpists, sax players, and more. Or by occasion, like drinks reception or main entertainment. Found someone you love? Send them a direct request, and if they’re available, they’ll get back to you with a quote.</p>

              </div>
            </div>

        </div>

      </div>

      <div style={{width: '100vw', textAlign:'center'}}>
        <img 
          className='mobile-remove' 
          src={filters} style={{maxWidth: '100vw', width: 1000}}          
          alt='First Dance Music | How to book your wedding music'        
        />
        <img 
          className='mobile' 
          src={filtersMobile} 
          style={{maxWidth: '100vw', width: 1000}}
          alt='First Dance Music | How to book your wedding music'
        />
      </div>
      
      {/* Step 3 */}

      <div className='client-how-it-works-step'>
        <div className='client-how-it-works-step-container' id='bell-container'>
          <img 
            style={{width: 300}} 
            src={bell} 
            className='client-how-it-works-img mobile-remove'
            alt='First Dance Music | How to book your wedding music'
          />

          <div className='client-how-it-works-step-info' style={{maxWidth: 650}}>
            <h2 style={{color: '#EABAB7', fontWeight: 900, fontSize: 40, margin: 0}}>03.</h2>
            <h2 style={{fontWeight: 900, fontSize: 26}}>Sit back & wait for quotes</h2>
            <p>If you posted a general request, musicians will start sending quotes directly to your dashboard. We’ll email you when new quotes arrive, or you can log in anytime to check.</p>
          </div>

          <img 
            style={{width: '40%'}} 
            src={bell} 
            className='client-how-it-works-img mobile'
            alt='First Dance Music | How to book your wedding music'
          />

        </div>
      </div>

      {/* Step 4 */}

      <div className='client-how-it-works-step-container' style={{justifyContent: 'unset', position: 'relative', width: 1300, paddingBottom: 0}}>
        <img 
          src={hearts} 
          style={{position: 'absolute', width: 200, maxWidth: '25%', top: -50, right: 50, zIndex: 999}}
          alt='First Dance Music | How to book your wedding music'
        />
        <div className='client-how-it-works-step-info' style={{maxWidth: 850}}>
            <h2 style={{color: '#EABAB7', fontWeight: 900, fontSize: 40, margin: 0}}>04.</h2>
            <h2 style={{fontWeight: 900, fontSize: 26}}>Review quotes, chat to musicians & make decisions</h2>
            <p>From your dashboard, you can:</p>

            <ul>
              <li>Review and respond to quotes via the messaging tool.</li>
              <li>Accept a quote from your preferred musician.</li>
              <li>Add another request if you’d like more acts, such as a DJ for the reception or a soloist for your aisle walk.</li>
              <li>Or, if you need more time, to think or discuss with the other half, pause incoming quotes at any time with the push of a button.</li>
            </ul>

          </div>
      </div>

      <div style={{width: '100vw', textAlign:'center'}}>
        <img 
          src={dashboard} 
          style={{maxWidth: '100vw', width: 1000}}
          alt='First Dance Music | How to book your wedding music'
        />
      </div>

      {/* Step 5 */}

      <div className='client-how-it-works-step'>
        <div className='client-how-it-works-step-container' style={{justifyContent: 'unset', position:'relative'}}>
          <img 
            className='mobile-remove' 
            src={imageAndHearts} style={{position: 'absolute', width: 600, maxWidth: '40%', bottom: -250, right: 50}}
            alt='First Dance Music | How to book your wedding music'
          />
          <div className='client-how-it-works-step-info' style={{width: 900, maxWidth: '100%'}}>
            <h2 style={{color: '#EABAB7', fontWeight: 900, fontSize: 40, margin: 0}}>05.</h2>
            <h2 style={{fontWeight: 900, fontSize: 26}}>Secure your act...stress-free</h2>
            <p>You can choose either of the following options:</p>
            <ul>
              <li>Pay upfront to get everything sorted right away and tick one more thing off your to-do list.</li>
              <li>Spread payments with our interest-free plan to ease the financial strain while securing your dream musician.*</li>
            </ul>

            <div id='hero-highlights' style={{marginTop: 50, marginBottom: 50, maxWidth: '100%'}}>
                <div className='hero-highlight' style={{padding: 0, marginRight: 50, width: 110}}>
                    <img 
                      src={finance} 
                      className='hero-icon'
                      alt='First Dance Music | How to book your wedding music'
                    />
                    <p>Spread the cost with no interest</p>
                </div>
                <div className='divider-vertical' />
                <div className='hero-highlight' style={{margin: '0 50px', padding: 0,  width: 110}}>
                    <img 
                      src={credit} 
                      className='hero-icon'
                      alt='First Dance Music | How to book your wedding music'
                    />
                    <p>no credit checks required</p>
                </div>
            </div>

            <p style={{width: 600, maxWidth: '90vw', fontSize: 12, fontWeight: 400, color: 'grey'}}>
              *You can spread payments monthly (with 0% interest), with the final payment due one month before your wedding day. For full details, see our terms and conditions.
            </p>
          </div>
        </div>
      </div>

      <img 
        style={{width: '100%', marginBottom: 0}} 
        src={imageAndHeartsMobile} 
        className='client-how-it-works-img mobile'
        alt='First Dance Music | How to book your wedding music'
      />

      {/* Step 6 */}
      <div className='client-how-it-works-step-container' style={{justifyContent: 'unset'}}>
          <img 
            style={{width: 300}} 
            src={phone} 
            className='client-how-it-works-img mobile-remove'
            alt='First Dance Music | How to book your wedding music'
          />

          <div className='client-how-it-works-step-info' style={{maxWidth: 650}}>
            <h2 style={{color: '#EABAB7', fontWeight: 900, fontSize: 40, margin: 0}}>06.</h2>
            <h2 style={{fontWeight: 900, fontSize: 26}}>Enjoy the countdown to your big day!</h2>
            <p>Log into your dashboard anytime to use the wedding day planner tool.</p>
          </div>

          <img 
            style={{width: 300}} 
            src={phone} 
            className='client-how-it-works-img mobile'
            alt='First Dance Music | How to book your wedding music'
          />

      </div>

      <div style={{backgroundColor: '#D9D9D9', width: '100vw'}}>
        <div className='client-how-it-works-step-container' >

            <div className='client-how-it-works-step-info' style={{maxWidth: 650}}>
              <h2 style={{fontWeight: 900, fontSize: 26}}>Need more music?</h2>
              <p>Remember You can request additional acts at any point! Whether it’s background music for the ceremony or something special while your guests enjoy cocktails, just click add another request on your dashboard whenever you’re ready.</p>
            </div>
            <img 
              id='need-more-music' 
              style={{width: 300}} 
              src={request} 
              className='client-how-it-works-img'
              alt='First Dance Music | How to book your wedding music'
            />

        </div>
      </div>

    </div>
  )
}
