import React from 'react'
import img1 from '../../Assets/app_1.webp'
import apple from '../../Assets/Icons/appstore.png'
import android from '../../Assets/Icons/googleplay.png'
import planner from '../../Assets/Icons/wedding-planner.png'
import solo from '../../Assets/Icons/singer.png'
import guests from '../../Assets/Icons/guests.png'
import checklist from '../../Assets/Icons/checklist.png'
import group from '../../Assets/app-group.webp'
import './AppLandingPage.css'

export default function AppLandingPage() {
  return (
    <div>
      <div className='app-download-container'>
        <div className='app-download-info'>
          <p id='app-header-txt'>Download our app today and start planning your big day!</p>
          <p style={{color: '#1e1e1e', fontWeight: 600, fontSize: 20}}>Our all-in-one wedding planning app is here to make your dream day effortless. From scheduling your big day to finding the perfect wedding music!</p>
          <div>
            <a href='https://apps.apple.com/uk/app/first-dance/id6739738754' target='_blank'>
              <img className='app-icon' style={{marginRight: 10}} src={apple} alt='First Dance Music | Free Wedding Planner App for Wedding bands & singers'/>
            </a>
            <a href='https://play.google.com/store/apps/details?id=com.firstdance.firstdance' target='_blank'>
              <img className='app-icon' src={android} alt='First Dance Music | Free Wedding Planner App for Wedding bands & singers'/>
            </a>
          </div>
        </div>
        <img className='app-preview' src={img1} alt='First Dance Music | Free Wedding Planner App for Wedding bands & singers'/>
      </div>

      <div style={{ backgroundColor: '#FCE0E0'}} >
        <div id='why-download'>
          <h2>Why Download Our App?</h2>
          <div id='app-features'>
            <div className='app-feature-container'>
              <img className='app-feature-icon' src={planner} alt='First Dance Music | Free Wedding Planner App Feature'/>
              <div>
                <h3>Day Planner & Scheduler</h3>
                <p>Map out every minute of your wedding day with ease! Keep your timeline organised so you can relax and enjoy your special moments.</p>
              </div>
            </div>
            <div className='app-feature-container'>
              <img className='app-feature-icon' src={solo} alt='First Dance Music | Free Wedding Planner App Feature'/>
              <div>
                <h3>Find Perfect Wedding Music</h3>
                <p>Discover the right musicians for your wedding day. Swipe through profiles, watch videos, and get instant quotes. Love their vibe? Chat with them directly to make arrangements.</p>
              </div>
            </div>
            <div className='app-feature-container'>
              <img className='app-feature-icon' src={guests} alt='First Dance Music | Free Wedding Planner App Feature'/>
              <div>
                <h3>Guest List & Seating Plan</h3>
                <p>Manage RSVPs and create seating arrangements all in one place—no spreadsheets, no confusion.</p>
              </div>
            </div>
            <div className='app-feature-container'>
              <img className='app-feature-icon' src={checklist} alt='First Dance Music | Free Wedding Planner App Feature'/>
              <div>
                <h3>Comprehensive Checklist</h3>
                <p>From sending invites to booking vendors, our app guides you with a checklist tailored to every stage of your wedding planning journey.</p>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className='app-download-container' style={{justifyContent:'center'}}>
        <div className='app-download-info'>
          <p style={{color: '#1e1e1e', fontWeight: 600, fontSize: 24, margin: 0}}>Your dream wedding starts here.</p>
          <p style={{color: '#1e1e1e', fontWeight: 900, fontSize: 24, marginTop: 0}}>Download the app today!</p>
          <div>
            <a href='https://apps.apple.com/uk/app/first-dance/id6739738754' target='_blank'>
              <img className='app-icon' style={{marginRight: 10}} src={apple} alt='First Dance Music | Free Wedding Planner App for Wedding bands & singers'/>
            </a>
            <a href='https://play.google.com/store/apps/details?id=com.firstdance.firstdance' target='_blank'>
              <img className='app-icon' src={android} alt='First Dance Music | Free Wedding Planner App for Wedding bands & singers'/>
            </a>
          </div>
        </div>
        <img className='app-images' src={group} alt='First Dance Music | Free Wedding Planner App for Wedding bands & singers'/>
      </div>
    </div>
  )
}
